import React, {ReactElement} from 'react';
import {useRouter} from 'next/router';
import {Box, Button, Heading, IconExclamation} from 'degen';

const NotFound = (): ReactElement => {
  const router = useRouter();

  return (
    <Box paddingBottom="10"> 
        <div className="centerHeader">
          <div className="box center-z">
            <p className="headingLarge bottom-0">404</p>  
            <p className="subheading">• Page not found</p> 
            <Button onClick={() => router.push('/')}>←</Button>
          </div>
        </div>
    </Box> 
  );
};

export default NotFound;
